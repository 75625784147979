import React from "react";
import Seo from "../../components/seo";
import Industry from "../../layouts/Industries/Industry";
import {graphql, useStaticQuery} from 'gatsby';
import Layout from "../../components/layout";
import {Restauration} from '../../components/svgs/industries';

const RestaurationIndustryPageFr = () => {
  const data = useStaticQuery(graphql`
    query RestaurationIndustryDatasFr {
      allContentfulSecteurs(filter: {contentful_id: {eq: "72qEQKcsgYTajRzrrv7WcA"}}) {
        edges {
          node {
            name
            permalink
            contentful_id
            advantages {
              title
              description
              icon
              visual {
                gatsbyImageData(layout: CONSTRAINED)
              }
            }
            installations {
              gatsbyImageData(layout: CONSTRAINED)
            }
            clients {
              gatsbyImageData(layout: CONSTRAINED)
            }
          }
        }
      }
    }
  `)

  return (
    <Layout 
    slider={false} 
    switchLink={data.allContentfulSecteurs.edges[1].node.permalink} 
    lang="fr-CA"
    title={data.allContentfulSecteurs.edges[0].node.name} 
    subTitle="L'affichage dynamique en"
    icon={<Restauration/>}>
      <Seo title="Attractif - Création et gestion d'affichage numérique dynamique"
      description="Utilisez le système d'affichage dynamique mis au point par Attractif pour l'affichage de vos menus et promotion. Vous pouvez gérer par emplacement, ou centraliser tout votre réseau." 
      />
      <Industry datas={data.allContentfulSecteurs.edges[0].node}/>
    </Layout>
  )
}

export default RestaurationIndustryPageFr
